import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import Hero from "../../../components/hero";
import ContactPerson from "../../../components/contactPerson";
import { CaseList } from "../../../components/caseList";
import { Facts } from "../../../components/facts";
import { Count } from "../../../components/count";
import { Container } from "../../../components/container";
import { ServicesTeaser } from "../../../components/servicesTeaser";
import { PartnersGrid } from "../../../components/partnersGrid";

const CrownpeakPartner = () => {
  const images = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "hero-crownpeak.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      rene: file(relativePath: { eq: "profiles/rene-zoller.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
        }
      }
      crownpeak: file(relativePath: { eq: "partner/crownpeak-partner.png" }) {
        ...pngTracedLogo
      }
      crownpeakPremier: file(
        relativePath: { eq: "partner/crownpeak-partner_premier.png" }
      ) {
        ...pngTracedLogo
      }
      crownpeakTechnology: file(
        relativePath: { eq: "partner/crownpeak-partner_technology.png" }
      ) {
        ...pngTracedLogo
      }
      crownpeakQAP: file(
        relativePath: { eq: "partner/crownpeak-partner_qap.png" }
      ) {
        ...pngTracedLogo
      }
      netlify: file(relativePath: { eq: "partner/netlify-partner.png" }) {
        ...pngTracedLogo
      }
    }

    fragment pngTracedLogo on File {
      childImageSharp {
        gatsbyImageData(
          width: 380
          placeholder: DOMINANT_COLOR
          layout: CONSTRAINED
        )
      }
    }
  `);

  return (
    <Layout lang="de" langPath="partner.crownpeak.link">
      <Seo
        title="FirstSpirit-Implementierungspartner – Monday Consulting"
        description="Als „Premier Partner” von Crownpeak gehören wir zu den bestausgebildeten Consultants für die FirstSpirit Digital Experience Platform."
        keywords="FirstSpirit, Crownpeak, Implementierungspartner, CMS, Premier Partner, e-Commerce"
      />
      <div className="parallax-translate bg-shape-dots"></div>
      <Hero
        headline="FirstSpirit – das Hybrid CMS von Crownpeak"
        subline="Mit der innovativen DXP von Crownpeak entwickeln wir passgenaue Lösungen und integrieren sie in bestehende Systeme."
        section="Crownpeak Partner"
        styleClass="mb-lg-5"
        bgColor="green"
      >
        <GatsbyImage
          image={images.hero.childImageSharp.gatsbyImageData}
          className="hero-image"
          alt="Crownpeak"
        />
      </Hero>

      <div className="container mb-4 mb-md-5 mt-md-5 my-5">
        <div className="row flex-row-reverse">
          <div className="col-12 col-md-8 mb-4">
            <h2>E-Commerce-CMS mit Fokus auf Content</h2>
            <p>
              Die FirstSpirit Digital Experience Platform (DXP) von Crownpeak
              macht aus Ihren Kunden regelrechte Fans. Die Hybrid
              Headless-Architektur ermöglicht es, personalisierte Inhalte noch
              schneller zu erstellen und über jegliche Kanäle auszuspielen. So
              begleiten Sie Ihre Kunden entlang der gesamten Customer Journey
              und stärken Ihre Kundenbindung nachhaltig. Mit dem Headless
              E-Commerce-CMS erwecken Sie Ihren Produktkatalog zum Leben und
              personalisieren KI-basiert die Interaktionen und Erlebnisse mit
              Ihren Kunden.
            </p>
            <p>
              Aufgrund unserer langjährigen Zusammenarbeit mit Crownpeak sowie
              der erfolgreichen Entwicklung von gemeinsamen Lösungen zählen wir
              zum kleinen Kreis der weltweiten „Premier Partnern”. Wir gehören
              zu den bestausgebildeten Consultants für die FirstSpirit Digital
              Experience Platform und stehen Ihnen gern mit unserem
              Spezialwissen – insbesondere bei komplexen Projekten – als
              Implementierungspartner zu Seite.
            </p>
            <p>
              Gezielte Kundenansprache, E-Commerce Ansätze, mobile und
              responsive Designs: Auf der Basis von FirstSpirit entwerfen wir
              Ihre individuelle und aufgeweckte CMS-Lösung mit dem Fokus auf
              hervorragende Usability – ob in der Cloud oder durch eine
              On-Premises-Lösung.
            </p>
            <p>
              Mit dem Status „Quality Approved Project” geben wir gemeinsam mit
              Crownpeak ein Qualitätsversprechen ab, auf das sich unsere Kunden
              bei der Realisierung ihres Projektes verlassen können.
            </p>
          </div>
          <div className="col-12 col-md-4 px-4 d-flex flex-column align-items-center">
            <div className="w-75 mb-3">
              <GatsbyImage
                image={images.crownpeak.childImageSharp.gatsbyImageData}
                className="w-100"
                alt="Crownpeak"
              />
            </div>
            <div className="w-75 mb-3">
              <GatsbyImage
                image={images.crownpeakPremier.childImageSharp.gatsbyImageData}
                className="w-100"
                alt="FirstSpirit Premier Partner by Crownpeak"
                title="FirstSpirit Premier Partner by Crownpeak"
              />
            </div>
            <div className="w-75 mb-3">
              <GatsbyImage
                image={
                  images.crownpeakTechnology.childImageSharp.gatsbyImageData
                }
                className="w-100"
                alt="FirstSpirit Technology Partner by Crownpeak"
                title="FirstSpirit Technology Partner by Crownpeak"
              />
            </div>
            <div className="w-75 mb-3">
              <GatsbyImage
                image={images.crownpeakQAP.childImageSharp.gatsbyImageData}
                className="w-100"
                alt="FirstSpirit Quality Approved Project by Crownpeak"
                title="FirstSpirit Quality Approved Project by Crownpeak"
              />
            </div>
            <div className="w-75 mb-3">
              <GatsbyImage
                image={images.netlify.childImageSharp.gatsbyImageData}
                className="w-100"
                alt="Netlify Registered Partner"
                title="Netlify Registered Partner"
              />
            </div>
          </div>
        </div>
      </div>

      <Container shape="square">
        <div className="container mt-6 mb-4 mb-md-5">
          <h2 className="col-12 text-center">
            Gute Gründe für Monday Consulting und FirstSpirit
          </h2>
          <Facts>
            <Count count="100" suffix="%">
              Alle FirstSpirit-Zertifikate im Unternehmen vorhanden.
            </Count>
            <Count count="2021">
              Monday Consulting wurde nach 10 Jahren erfolgreicher
              Zusammenarbeit zum “Premier Partner”.
            </Count>
            <Count count="30" prefix=">">
              Mehr als 30 Kunden konnten wir mit FirstSpirit-Projekten
              begeistern.
            </Count>
          </Facts>
        </div>
      </Container>

      <div className="container">
        <div className="row mb-5 mb-md-6 flex-md-row-reverse reveal">
          <div className="col-12 pb-4 mb-md-0 col-md-5 bg-shape-cross bg-position-tl bg-size-50">
            <span className="countable text-size-0 text-color-monday-skyblue">
              1
            </span>
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">Headless-Architektur</h2>
            <h3>Content-driven E-Commerce</h3>
            <p>
              Die Headless-Architektur bietet Ihnen die Möglichkeit, Ihre Kunden
              mit hochwertigem einzigartigem Content an allen Punkten der
              Customer Journey zu begeistern und sie an sich zu binden – digital
              und vor Ort. Sie vereinen die Vorteile der digitalen und realen
              Welt zu einem nahtlosen Einkaufserlebnis. Die direkt erlebbaren
              Eindrücke werden durch personalisiertes Storytelling, besonderen
              Komfort und unbegrenzte Verfügbarkeit ergänzt.
            </p>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 reveal">
          <div className="col-12 pb-4 mb-md-0 col-md-5 bg-shape-dots bg-position-tr bg-size-50">
            <span className="countable text-size-0 text-color-monday-skyblue text-right">
              2
            </span>
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">
              Personalisierte Inhalte
            </h2>
            <h3>FirstSpirit Intelligent Content Engine</h3>
            <p>
              Nutzen Sie die FirstSpirit Intelligent Content Engine (ICE), um
              personalisierte, relevante Inhalte bereitzustellen, die Kunden
              gezielt anzusprechen und zum Handeln zu bewegen. Unser
              ICE-zertifiziertes Team kann Sie im Bereich Personalisierung
              bestens beraten und unterstützt Sie gern dabei, den digitalen
              Draht zu Ihren Kunden zu festigen.
            </p>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 flex-md-row-reverse reveal">
          <div className="col-12 pb-4 mb-md-0 col-md-5 bg-shape-circle bg-position-tl bg-size-50">
            <span className="countable text-size-0 text-color-monday-skyblue">
              3
            </span>
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">
              Heterogene Systemlandschaften
            </h2>
            <h3>FirstSpirit DXP als Integrationsplatform</h3>
            <p>
              Unsere FirstSpirit-Experten integrieren Ihre bestehenden
              Anwendungen auch in komplexen Systemlandschaften in die
              FirstSpirit Digital Experience Platform. So können Sie jedes
              beliebige CRM- oder Shop-System weiterhin einsetzen und schaffen
              eine einzigartige User Experience für Ihre Kunden.
            </p>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 reveal">
          <div className="col-12 pb-4 mb-md-0 col-md-5 bg-shape-square bg-position-tr bg-size-50">
            <span className="countable text-size-0 text-color-monday-skyblue text-right">
              4
            </span>
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">
              Professionelles Formularmanagement
            </h2>
            <h3>Formcentric für FirstSpirit</h3>
            <p>
              Für unseren Formularmanager und dessen Erweiterung Analytics
              wurden wir als „FirstSpirit Technology Partner” ausgezeichnet.
              Formcentric wird bereits von vielen Kunden als zentrale
              Integrationsplattform für ihre Formulardaten genutzt. Mit
              Formcentric erweitern Sie Ihren Webauftritt um professionelle
              HTML-Formulare, die sich optimal in Ihr Design einfügen. So
              erarbeiten Sie im Handumdrehen eine einzigartige Customer
              Experience, ganz bequem direkt aus dem FirstSpirit ContentCreator.
            </p>
            <a
              href="https://formcentric.com/de/"
              target="_blank"
              className="btn mt-1"
              rel="noopener noreferrer"
            >
              Mehr zu Formcentric
            </a>
          </div>
        </div>
      </div>

      <PartnersGrid
        title="Spezialisierung für Ihren Erfolg"
        teaser={
          "Mit unseren starken Partnerschaften gestalten wir barrierefreie und individuelle Erlebnisse von morgen."
        }
      />

      <ServicesTeaser
        title="Entdecken Sie unsere Möglichkeiten"
        text={
          "Mit unserer Expertise in Strategie und Konzeption gestalten wir gemeinsam optimale Lösungen, die heute die Standards von morgen erfüllen.\nVon der Auswahl des idealen Content-Management-Systems über einzigartiges Design und Kreation bis hin zur Optimierung von Frontend, Backend und Infrastruktur – wir bieten Ihnen alles aus einer Hand.\n\nWir begleiten Sie durch den gesamten Prozess und stehen auch nach Projektabschluss mit erstklassigem Customer Care zur Seite."
        }
        ctaLink="/leistungen/leistungsportfolio"
        ctaText="Unsere Leistungen im Überblick"
      />

      <div
        className="content-container mt-5 vertical-spacing bg-monday-skyblue bg-pattern-vertigo text-color-white text-center"
        data-spacing-bottom="cases"
      >
        <div className="container pt-4 pt-md-5 pb-3 reveal">
          <div className="row mb-3">
            <div className="col-12 d-flex flex-column align-items-center">
              <h3>Ausgewählte FirstSpirit-Referenzen</h3>
            </div>
          </div>
        </div>
      </div>
      <div
        id="cases"
        className="container vertical-offset reveal mb-md-6 mb-5"
        data-offset="50%"
      >
        <CaseList category="firstspirit" count="2" />
      </div>

      <ContactPerson
        headline="Legen Sie Ihr FirstSpirit-Projekt in unsere Hände."
        text="Für unsere Experten ist keine Herausforderung zu groß. Teilen Sie uns noch heute Ihre Wünsche und Anforderungen mit."
        contactEmail="firstspirit@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="René Zoller, Unit-Leiter FirstSpirit"
      >
        <GatsbyImage
          image={images.rene.childImageSharp.gatsbyImageData}
          alt="René Zoller"
        />
      </ContactPerson>
    </Layout>
  );
};

export default CrownpeakPartner;
